// core
import { mapGetters } from 'vuex';

// helpers
import LoadImage from '@/Views/_Components/Helpers/Image/index.vue';

// icons
import { UserIcon, HeartIcon, FileTextIcon, LogOutIcon, ActivityIcon, XIcon } from 'vue-feather-icons';

export default {
	name: 'OstonMenu',

	components: {
		'oston-image': LoadImage,

		// icons
		'feather-icon-close': XIcon,
		'feather-icon-logout': LogOutIcon,
		'feather-icon-terms': FileTextIcon,
		'feather-icon-heart': HeartIcon,
		'feather-icon-user': UserIcon,
		'feather-icon-question': ActivityIcon
	},

	mounted() {
		_.controller('menu').reset();
	},

	methods: {
		togglr: function() {
			_.controller('menu').open();
		},
		logout: function() {
			_.controller('auth').logout();
		},
		redirect: function(href, type = '') {
			if (!!type) _.controller('book').type(type);
			_.controller('menu').open();

			if (this.$router.history.current.path !== href) this.$router.push({ path: href });
		}
	},

	computed: mapGetters({
		status: 'MenuModel/status'
	})
};
