// icons
import { ChevronDownIcon } from 'vue-feather-icons';

export default {
	name: 'Accordion',

	data() {
		return {
			active: false
		};
	},

	props: {
		title: {
			type: String,
			required: true,
			default: () => console.log('Accordion: The TITLE is required')
		}
  },

  components: {
    'feather-icon-arrow-down': ChevronDownIcon
  }
};
