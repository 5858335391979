// settings
import Settings from '@/config/Settings';

// components
import Layout from '@/Views/_Layout/index.vue';
import HelpMenu from '@/Views/_Components/HelpMenu/index.vue';
import HeaderSmall from '@/Views/_Components/Header/Small/index.vue';
import Accordion from '@/Views/_Components/Accordion/index.vue';

import LoadImage from '@/Views/_Components/Helpers/Image/index.vue';

export default {
	name: 'Faq',

	components: {
		'oston-layout': Layout,
    'oston-help-menu': HelpMenu,
    'oston-header': HeaderSmall,
    'oston-accordion': Accordion,

    // helpers
    'oston-image': LoadImage,
  },

  data() {
    return {
      isVisible: false,
    }
  },

	mounted() {
		Settings.title('Dúvidas Frequentes');
  },
};
