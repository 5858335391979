import { SearchIcon, UserIcon, MenuIcon, HeartIcon } from 'vue-feather-icons'


export default {
  name: 'HelpMenu',

  components: {
    'feather-icon-user': UserIcon,
    'feather-icon-search': SearchIcon,
    'feather-icon-fav': HeartIcon,
    'feather-icon-menu': MenuIcon
  },

  methods: {
    togglr: function() {
      _.controller('menu').open()
    },

    select: function(value) {
      _.controller('book').type(value)
    }
  }
}
