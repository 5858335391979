// components
import Menu from '@/Views/_Components/Menu/index.vue';

export default {
	name: 'OstonLayout',

	props: {
		layout: {
			type: String,
			required: false,
			default: 'default'
		}
  },

  components: {
    'oston-menu': Menu
  }
};
